/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { apiService } from '../services/FetchApiService'
import { embeddingService } from '../services/poc/EmbeddingService'

function AnalyticsPageById(props) {
  const { paramsID } = useParams()
  // console.log('🚀 ~ AnalyticsPageById ~ paramsID:', paramsID);

  const example1 = {
    loginMode: 1,
    projectID: '85E51F7A3D41E6EF80E4C1AF0824EC8D',
    dossierId: 'BE76232A564C10F9E8844E9DC150C533'
  }
  const example2 = {
    loginMode: 1,
    projectID: '85E51F7A3D41E6EF80E4C1AF0824EC8D',
    dossierId: 'D7977FE54D3596F581A1538AA7E19461'
  }
  const example3 = {
    loginMode: 1,
    projectId: '85E51F7A3D41E6EF80E4C1AF0824EC8D',
    dossierId: '2B2938314B300F688554CC9DAEF668B6'
  }
  const exampleSara = {
    projectID: '85E51F7A3D41E6EF80E4C1AF0824EC8D',
    dossierId: '2B2938314B300F688554CC9DAEF668B6',
    loginMode: 4194304
    //
  }

  const config = {
    baseURL: process.env.REACT_APP_BASE_URL_CIVICA || 'https://mstr.civica-soft.com/mstrlib-oidc/',
    username: process.env.REACT_APP_CIVICA_USERNAME || 'mstr',
    password: process.env.REACT_APP_CIVICA_PASSWORD || 'qQy8S3Dk5cobQZhR',
    projectID: '85E51F7A3D41E6EF80E4C1AF0824EC8D',
    // dossierId: 'BE76232A564C10F9E8844E9DC150C533',
    loginMode: 1,
    dossierId: paramsID
    // navigationBar: {
    // title: false,
    // },
    // enableResponsive: false,
    // ...example1,
  }

  const [cookies] = useCookies()
  const [error, setError] = useState()
  const [azureToken, setAzureToken] = useState()
  const [stateCookies, setStateCookie] = useState()

  const getCookieFromWebView = () => {
    try {
      const azuToken = cookies.azureToken
      setAzureToken(azuToken)
      setStateCookie(cookies)
    } catch (e) {
      setError(true)
    }
  }

  const createDossierInstance = async () => {
    return embeddingService
      .createDossierInstance(config)
      .then((instance) => {
        if (instance != null) {
          embeddingService.renderDossier(
            document.getElementById('analytics-container'),
            config.baseURL,
            config.projectID,
            config.dossierId,
            instance
          )
        }
      })
      .catch((error) => {
        new Error('Error creating dossier instance:', error)
      })
  }

  useEffect(() => {
    setError(false)
    getCookieFromWebView()
    // console.log('probando');
    // document.getElementById('testing').innerHTML = 'probandoo';
    const testingApi = async () => {
      // TODO: Change this string param
      const res = await apiService.getDashboard(24)
      return res
    }
    // testingApi();

    try {
      setError(false)
      createDossierInstance()
    } catch (error) {
      setError(true)
    }
  }, [])

  return (
    <>
      {/* <p id="errorTextApi" /> */}

      <Box id="analytics-container" sx={{ margin: '1%', height: '90% !important', width: '100%' }} />
    </>
  )
}
export default AnalyticsPageById
